const skyconText = (skycon) => {
    let text;
    switch (skycon) {
        case 'CLEAR_DAY':
            text = '晴'
            break;
        case 'CLEAR_NIGHT':
            text = '晴'
            break;
        case 'PARTLY_CLOUDY_DAY':
            text = '多云'
            break;
        case 'PARTLY_CLOUDY_NIGHT':
            text = '多云'
            break;
        case 'CLOUDY':
            text = '阴'
            break;
        case 'LIGHT_HAZE':
            text = '轻度雾霾'
            break;
        case 'MODERATE_HAZE':
            text = '中度雾霾'
            break;
        case 'HEAVY_HAZE':
            text = '重度雾霾'
            break;
        case 'LIGHT_RAIN':
            text = '小雨'
            break;
        case 'MODERATE_RAIN':
            text = '中雨'
            break;
        case 'HEAVY_RAIN':
            text = '大雨'
            break;
        case 'STORM_RAIN':
            text = '暴雨'
            break;
        case 'FOG':
            text = '雾'
            break;
        case 'LIGHT_SNOW':
            text = '小雪'
            break;
        case 'MODERATE_SNOW':
            text = '中雪'
            break;
        case 'HEAVY_SNOW':
            text = '大雪'
            break;
        case 'STORM_SNOW':
            text = '暴雪'
            break;
        case 'DUST':
            text = '浮尘'
            break;
        case 'SAND':
            text = '沙尘'
            break;
        case 'WIND':
            text = '大风'
            break;
        default:
            break;
      }
    return text;
}
const weatherIcon = (skycon) => {
    let text;
    switch (skycon) {
        case 'd00':
            text = '晴'
            break;
        case 'd01':
            text = '多云'
            break;
        case 'd02':
            text = '阴天'
            break;
        case 'd03':
            text = '阵雨'
            break;
        case 'd04':
            text = '雷阵雨'
            break;
        case 'd05':
            text = '雷阵雨伴有冰雹'
            break;
        case 'd06':
            text = '雨夹雪'
            break;
        case 'd07':
            text = '小雨'
            break;
        case 'd08':
            text = '中雨'
            break;
        case 'd09':
            text = '大雨'
            break;
        case 'd10':
            text = '暴雨'
            break;
        case 'd11':
            text = '大暴雨'
            break;
        case 'd12':
            text = '特大暴雨'
            break;
        case 'd13':
            text = '阵雪'
            break;
        case 'd14':
            text = '小雪'
            break;
        case 'd15':
            text = '中雪'
            break;
        case 'd16':
            text = '大雪'
            break;
        case 'd17':
            text = '暴雪'
            break;
        case 'd18':
            text = '雾'
            break;
        case 'd19':
            text = '冻雨'
            break;
        case 'd20':
            text = '沙尘暴'
            break;
        case 'd21':
            text = '小到中雨'
            break;
        case 'd22':
            text = '中到大雨'
            break;
        case 'd23':
            text = '大到暴雨'
            break;
        case 'd24':
            text = '暴雨到大暴雨'
            break;
        case 'd25':
            text = '大暴雨到特大暴雨'
            break;
        case 'd26':
            text = '小到中雪'
            break;
        case 'd27':
            text = '中到大雪'
            break;
        case 'd28':
            text = '大到暴雪'
            break;
        case 'd29':
            text = '浮沉'
            break;
        case 'd30':
            text = '扬沙'
            break;
        case 'd31':
            text = '强沙尘暴'
            break;
        case 'd53':
            text = '霾'
            break;
	}
	return text;
}
export default {skyconText, weatherIcon}
