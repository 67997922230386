





















import Vue from 'vue'
import * as echarts from 'echarts'
import cyFormat from '@/utils/cyFormat'
import skyconText from '@/utils/skyconText'
import EchartsHourlyNew from './../EchartsHourlyNew'
import moment from 'moment'
export default Vue.extend({
  props: {
    data: { type: Object },
  },
  watch: {
    data() {
      this.getData()
    },
  },
  data() {
    return {
      hoursData: [],
    }
  },
  computed: {
    skyconDesc(): string {
      return cyFormat.getSkyconDesc(this.data?.skycon)
      // return skyconText.skyconText(this.data?.skycon)
    },
    skyconIcon(): string {
      return cyFormat.getSkyconIcon(this.data?.skycon)
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      // console.log(this.data)
      const tempData = this.data.temperature.slice(0, 25)
      const skycon = this.data.skycon.slice(0, 25)
      const hoursData = tempData.map((e: any) => {
        return {
          tem: e.value.toFixed(1),
          time: e.datetime.split('T')[1].split('+')[0],
        }
      })
      hoursData.map((e: any) => {
        skycon.map((k: any) => {
          if (e.time === k.datetime.split('T')[1].split('+')[0]) {
            e.imgUrl = `/static/weatherIcon/${k.value}.png`
            e.skyconText = skyconText.skyconText(k.value)
          }
        })
      })
      this.hoursData = hoursData.slice(1, 25)
      // this.hoursData = hoursData


      const dom: any = document.getElementById('echartsDay')
      const myChart: any = echarts.init(dom) // 基于准备好的dom，初始化echarts实例
      window.onresize = myChart.resize;  // 图表宽度自适应
      const time = []
      const tem = []
      this.hoursData.map((item, index) => {
        time.push(item.time)
        tem.push(item.tem)
      })
      myChart.setOption(EchartsHourlyNew(time, tem))

    },
  },
  filters: {
    dtFormat(timestamp: number) {
      const time = moment(timestamp * 1000)
      return time.format('M/D HH:00')
    }
  }
})
