<template>
	<div class="area">
		<div class="title">
			<span>选择站点</span>
			<div class="btn">
				<div @click="submitAlert(false)">取消</div>
				<div @click="submitAlert(true)">确定</div>
			</div>
		</div>
		<div class="content">
			<div :class="[id=== 'location'?'item-parent-active':'','item-parent-text']" 
				@click="changeArea('location')">当前定位</div>
			<div v-for="(itemParent, indexParent) in baseData" :key="indexParent">
				<div :class="[id=== itemParent.id?'item-parent-active':'','item-parent-text']" @click="changeArea(itemParent.id)">{{itemParent.name}}</div>

				<div class="item-parent">
					<div v-for="(itemChild, indexChild) in itemParent.children" :key="indexChild">
						<div :class="[id=== itemChild.id?'item-parent-active':'','item-parent-text']" @click="changeArea(itemChild.id)">{{itemChild.name}}</div>
						
						<div class="item-con">
							<div v-for="(item, index) in itemChild.children" :key="index">
								<div :class="[id=== item.id?'item-parent-active':'','item-parent-text']" @click="changeArea(item.id)">{{item.name}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import request from '@/utils/request'
export default{
	props:['areaId'],
	data() {
		return{
			allData: [],
			baseData: [],
			id: '',
		}
	},
	created() {
		this.id = this.areaId
		this.getStationList()
	},
	methods:{
		getStationList() {
			return request.get('/api/sys/area/all_list').then(data => {
				// console.log(data)
				this.allData = data
				const areaList = []
				const areaCity = []
				const arrtown = []
				data.map((e) => {
					// e.title =  `${e.id} ${e.name}`
					e.children = []
					if (!e.parentId) {
						areaList.push(e)
					} else if (String(e.id).length === 6) {
						areaCity.push(e)
					} else {
						arrtown.push(e)
					}
				})
				areaCity.map((e) => {
					arrtown.map((i) => {
						if (e.id === i.parentId) {
							e.children.push(i)
						}
					})
				})
				areaList.map((e) => {
					e.children = areaCity
					e.expand = true
				})
				// console.log(arrtown)
				// console.log(areaCity)
				// console.log(areaList)
				this.baseData = areaList
			})
		},
		changeArea(id) {
			this.id = id
		},
		submitAlert(value) {
			// console.log(this.id)
			// console.log(this.allData)
			if (value) {
				if (this.id === 'location') {
					this.$emit('getAreaStation', 'location')
				} else {
					let obj = {}
					this.allData.map((e) => {
						if (this.id === e.id) {
							obj = e
						}
					})
					// console.log(obj)
					let areaName = ''
					if (obj.children.length === 0) {
						this.allData.map((e) => {
							if (obj.parentId === e.id) {
								areaName = e.name + obj.name
							}
						})
					} else {
						areaName = obj.name
					}
					const params = {
						lon: obj.lon,
						lat: obj.lat,
						areaId: obj.id,
						areaName,
					}
					// console.log(params)
					this.$emit('getAreaStation', params)
				}
			} else {
				this.$emit('getAreaStation', false)
			}
		},
	},
}
</script>

<style scoped lang="scss">
.area{
	width: calc(100vw - 0px);
	height: 100vh;
	background: #fff;
	position: fixed;
	top: 0;
	left: 0;
	padding: 20px 10px 20px;
	z-index: 30;
	color: #000;
	font-size: .16rem;
}
.title{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #3388FF;
	span{
		height: .4rem;
		line-height: .4rem;
		font-weight: 600;
	}
	.btn{
		display: flex;
		div{
			// height: .2rem;
			span-align: center;
			border-radius: 4px;
			background: #3388FF;
			color: #fff;
			padding: 5px 10px;
			margin-left: 20px;
		}
	}
}
.content{
	height: calc(100% - 0px - .4rem);
	overflow: scroll;
	// background: pink;
	text-align: left;
	font-size: .14rem;
	.item-con{
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 20px;
	}
	.item-parent-text{
		display: inline-block;
		margin: 4px;
		padding: 5px 8px;
		border-radius: 4px;
		line-height: 24px;
		background-color: #EDEFF8;
	}
	.item-parent-active{
		background-color: #459E75;
		color: #fff;
	}
}
// .stationTitle{
// 	line-height: 80rpx;
// 	font-weight: 600;
// }
// .stationText{
// 	background: #efc108;
// 	color: #fff;
// 	padding: 10rpx 10rpx;
// 	margin-right: 10rpx;
// 	border-radius: 5rpx;
// }
</style>
